<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-5086CGW5DK"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-5086CGW5DK');
</script>

<nav class="navbar navbar-expand-lg navigation fixed-top sticky" (window:scroll)="windowScroll()" id="navbar">
    <div class="container">
        <a class="navbar-logo" routerLink="/" aria-label="logo">
            <img src="assets/images/logo_side_dark.png" alt="" height="35" class="logo logo-dark">
            <img src="assets/images/logo_side.png" alt="" height="35" class="logo logo-light">
        </a>

        <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item"  aria-label="menu_toggle"
            data-toggle="collapse" data-target="#topnav-menu-content" (click)="toggleMenu()">
            <i class="fa fa-fw fa-bars"></i>
        </button> 

        <div class="collapse navbar-collapse" id="topnav-menu-content">
            <ul class="navbar-nav ms-auto" id="topnav-menu">
                <li class="nav-item">
                    <a aria-label="nav_home" class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'home'"
                        [ngClass]="{'active':currentSection === 'home'}">Home</a>
                </li>
                 <li class="nav-item">
                    <a aria-label="nav_about" class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'about'"
                        [ngClass]="{'active':currentSection === 'about'}">About</a>
                </li>
                <li class="nav-item">
                    <a aria-label="nav_features" class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'features'"
                        [ngxScrollToOffset]="5" [ngClass]="{'active':currentSection === 'features'}">Features</a>
                </li>
                <li class="nav-item">
                    <a aria-label="road_map" class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'roadmap'"
                        [ngxScrollToOffset]="5" [ngClass]="{'active':currentSection === 'roadmap'}">Roadmap</a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" href="javascript: void(0);" [ngxScrollTo]="'faqs'"
                        [ngClass]="{'active':currentSection === 'faqs'}">FAQs</a>
                </li>  -->

            </ul>

            <!-- <div class="my-2 ms-lg-2">
                <a href="javascript: void(0);" class="btn btn-outline-success w-xs">Sign in</a>
            </div> -->
        </div>
    </div>
</nav>

<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
    <!-- hero section start -->
    <section class="section hero-section bg-ico-hero" id="home">
        <div class="bg-overlay bg-primary"></div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <div class="text-white-50">
                        <h1 class="text-white fw-semibold mb-3 hero-title">Unlocking the Power of Data: Advanced Machine Learning Solutions for Businesses</h1>
                        <p class="font-size-14">Harnessing the power of artificial intelligence to deliver customized, cutting-edge machine learning solutions for your business needs.</p>

                        <!-- <div class="button-items mt-4 d-flex gap-2">
                            <a aria-label="demo" href="javascript: void(0);" class="btn btn-success">Demo</a>
                            <a aria-label="how_it_works" href="javascript: void(0);" class="btn btn-light">How it work</a>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-5 col-md-8 col-sm-10 ms-lg-auto">
                    <div class="card overflow-hidden mb-0 mt-5 mt-lg-0">
                        <div class="card-header text-center">
                            <h5 class="mb-0">Launch Countdown time</h5>
                        </div>
                        <div class="card-body">
                            <div class="text-center">

                                <h5>Time left to launch :</h5>
                                <div class="mt-4">
                                    <div inputDate="December 31, 2022" class="counter-number ico-countdown">
                                        <div class="coming-box">{{_days}}<span>Days</span>
                                        </div>
                                        <div class="coming-box">{{_hours}}<span>Hours</span>
                                        </div>
                                        <div class="coming-box">{{_minutes}}<span>Minutes</span>
                                        </div>
                                        <div class="coming-box">{{_seconds}}<span>Seconds</span>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="mt-4">
                                    <button type="button" class="btn btn-success w-md"  aria-label="get_updates">Get Updates</button>
                                </div> -->

                                <!-- <div class="mt-5">
                                    <h4 class="fw-semibold">1 ETH = 2235 SKT</h4>
                                    <div class="clearfix mt-4">
                                        <h5 class="float-end font-size-14">5234.43</h5>
                                    </div>
                                    <div class="progress p-1 progress-xl softcap-progress">
                                        <div class="progress-bar bg-info" role="progressbar" style="width: 15%"
                                            aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">
                                            <div class="progress-label">15 %</div>
                                        </div>
                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                            role="progressbar" style="width: 30%" aria-valuenow="30" aria-valuemin="0"
                                            aria-valuemax="100">
                                            <div class="progress-label">30 %</div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </section>
    <!-- hero section end -->

    <!-- currency price section start -->
    <section class="section bg-white p-0">
        <div class="container">
            <div class="currency-price">
                <div class="row">
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-shrink-0 me-3">
                                        <div class="avatar-xs">
                                            <span
                                                class="avatar-title rounded-circle bg-soft bg-warning text-warning font-size-18">
                                                <i class="mdi mdi-message-processing-outline"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <!-- <p class="text-muted">Chatbots</p> -->
                                        <h3>Chatbots</h3>
                                        <!-- <p class="text-muted text-truncate mb-0">+ 0.0012.23 ( 0.2 % ) <i
                                                class="mdi mdi-arrow-up ms-1 text-success"></i></p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-shrink-0 me-3">
                                        <div class="avatar-xs">
                                            <span
                                                class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-18">
                                                <i class="mdi mdi-brain"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <h3>AI Services</h3>
                                        <!-- <p class="text-muted">Ml&DL Services</p>
                                        <h5>$ 245.44</h5>
                                        <p class="text-muted text-truncate mb-0">- 004.12 ( 0.1 % ) <i
                                                class="mdi mdi-arrow-down ms-1 text-danger"></i></p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-shrink-0 me-3">
                                        <div class="avatar-xs">
                                            <span class="avatar-title rounded-circle bg-soft bg-info text-info font-size-18">
                                                <i class="mdi mdi-robot-love-outline"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1">
                                        <h3>Automation</h3>
                                        <!-- <p class="text-muted">Automation</p>
                                        <h5>$ 63.61</h5>
                                        <p class="text-muted text-truncate mb-0">+ 0.0001.12 ( 0.1 % ) <i
                                                class="mdi mdi-arrow-up ms-1 text-success"></i></p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end row -->
            </div>
        </div>
        <!-- end container -->
    </section>
    <!-- currency price section end -->


    <!-- about section start -->
    <section class="section pt-4 bg-white" id="about">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <div class="small-title">About us</div>
                        <h4>How we help you!</h4>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-5">

                    <div class="text-muted">
                        <h4>Best srevices for your business</h4>
                        <p>We provide businesses with advanced machine learning solutions to optimize their operations and drive growth.</p>
                        <p>Our services include data analysis, predictive modeling, and automation to enhance decision-making and streamline processes.</p>
                        <p>We will understand and solve your specific needs and tailor our solutions to your unique requirements</p>
                        <p></p>

                        <!-- <p>If several languages coalesce, the grammar of the resulting that of the individual new common
                            language will be more simple and regular than the existing.</p>
                        <p class="mb-4">It would be necessary to have uniform pronunciation.</p> -->

                        <div class="button-items d-flex gap-2">
                            <a aria-label="read_more" href="javascript: void(0);" class="btn btn-success">Read More</a>
                            <a aria-label="row_it_works_2" href="javascript: void(0);" class="btn btn-outline-primary">How It work</a>
                        </div>

                        <!-- <div class="row mt-4">
                            <div class="col-lg-4 col-6">
                                <div class="mt-4">
                                    <h4>$ 6.2 M</h4>
                                    <p>Invest amount</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-6">
                                <div class="mt-4">
                                    <h4>16245</h4>
                                    <p>Users</p>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>

                <div class="col-lg-6 ms-auto">
                    <div class="mt-4 mt-lg-0">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="card border">
                                    <div class="card-body">
                                        <div class="mb-3">
                                            <i class="mdi mdi-brain h2 text-success"></i>
                                        </div>
                                        <h5>AI Services</h5>
                                        <p class="text-muted mb-0">Providing advanced machine learning solutions for business optimization.</p>

                                    </div>
                                    <div class="card-footer bg-transparent border-top text-center">
                                        <a aria-label="learnmore_2" href="javascript: void(0);" class="text-primary">Learn more</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="card border mt-lg-5">
                                    <div class="card-body">
                                        <div class="mb-3">
                                            <i class="mdi mdi-robot-outline h2 text-success"></i>
                                        </div>
                                        <h5>Automation</h5>
                                        <p class="text-muted mb-0">Streamlining business processes with cutting-edge automation solutions.</p>

                                    </div>
                                    <div class="card-footer bg-transparent border-top text-center">
                                        <a aria-label="learn_more" href="javascript: void(0);" class="text-primary">Learn more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <hr class="my-5">

            <!-- <div class="row">
                <div class="col-lg-12">
                    <owl-carousel-o [options]="carouselOption" class="events navs-carousel">
                        <ng-template carouselSlide>
                            <div class="item">
                                <div class="client-images">
                                    <img src="assets/images/clients/1.png" alt="client-img"
                                        class="mx-auto img-fluid d-block">
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="item">
                                <div class="client-images">
                                    <img src="assets/images/clients/2.png" alt="client-img"
                                        class="mx-auto img-fluid d-block">
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="item">
                                <div class="client-images">
                                    <img src="assets/images/clients/3.png" alt="client-img"
                                        class="mx-auto img-fluid d-block">
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="item">
                                <div class="client-images">
                                    <img src="assets/images/clients/4.png" alt="client-img"
                                        class="mx-auto img-fluid d-block">
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="item">
                                <div class="client-images">
                                    <img src="assets/images/clients/5.png" alt="client-img"
                                        class="mx-auto img-fluid d-block">
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="item">
                                <div class="client-images">
                                    <img src="assets/images/clients/6.png" alt="client-img"
                                        class="mx-auto img-fluid d-block">
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div> -->
            <!-- end row -->
        </div>
        <!-- end container -->
    </section>
    <!-- about section end -->

    <!-- Features start -->
    <section class="section" id="features">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <div class="small-title">Features</div>
                        <h4>Key features of the product</h4>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <div class="row align-items-center pt-4">
                <div class="col-md-6 col-sm-8">
                    <div>
                        <img src="assets/images/landing_ai.jpg" alt="" class="img-fluid mx-auto d-block">
                    </div>
                </div>
                <div class="col-md-5 ms-auto">
                    <div class="mt-4 mt-md-auto">
                        <div class="d-flex align-items-center mb-2">
                            <div class="features-number fw-semibold display-4 me-3">01</div>
                            <h4 class="mb-0">AI Services</h4>
                        </div>
                        <p class="text-muted">Machine learning can help businesses to optimize their operations and improve efficiency by identifying patterns and trends in data such as sales,customer interaction and customer behavior.
                        </p>
                        <div class="text-muted mt-4">
                            <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i>Finance & Education</p>
                            <p><i class="mdi mdi-circle-medium text-success me-1"></i>Retail</p>
                            <p><i class="mdi mdi-circle-medium text-success me-1"></i>Finance chatbots & HR chatbots</p>
                            <p><i class="mdi mdi-circle-medium text-success me-1"></i>Customer service</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <div class="row align-items-center mt-5 pt-md-5">
                <div class="col-md-5">
                    <div class="mt-4 mt-md-0">
                        <div class="d-flex align-items-center mb-2">
                            <div class="features-number fw-semibold display-4 me-3">02</div>
                            <h4 class="mb-0">Automation</h4>
                        </div>
                        <p class="text-muted">With automation, businesses can improve efficiency, reduce costs, and increase productivity by automating repetitive tasks, freeing up employees to focus on more complex and value-added activities.</p>
                        <div class="text-muted mt-4">
                            <p class="mb-2"><i class="mdi mdi-circle-medium text-success me-1"></i>Supply chain management</p>
                            <p><i class="mdi mdi-circle-medium text-success me-1"></i>Finance</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6  col-sm-8 ms-md-auto">
                    <div class="mt-4 me-md-0">
                        <img src="assets/images/landing_automation.jpg" alt="" class="img-fluid mx-auto d-block">
                    </div>
                </div>

            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </section>
    <!-- Features end -->

    <!-- Roadmap start -->
    <section class="section bg-white" id="roadmap">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <div class="small-title">Timeline</div>
                        <h4>Our Roadmap</h4>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <div class="row mt-4">
                <div class="col-lg-12">
                    <div class="hori-timeline">
                        <owl-carousel-o [options]="timelineCarousel" class="events navs-carousel">
                            <ng-template carouselSlide>
                                <div class="item event-list">
                                    <div>
                                        <div class="event-date">
                                            <div class="text-primary mb-1">December, 2022</div>
                                            <h5 class="mb-4">Platform Idea</h5>
                                        </div>
                                        <div class="event-down-icon">
                                            <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                        </div>

                                        <div class="mt-3 px-3">
                                            <p class="text-muted">We developed this idea through extensive brainstorming sessions over multiple days.</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="item event-list">
                                    <div>
                                        <div class="event-date">
                                            <div class="text-primary mb-1">January, 2023</div>
                                            <h5 class="mb-4">MVP 1</h5>
                                        </div>
                                        <div class="event-down-icon">
                                            <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                        </div>

                                        <div class="mt-3 px-3">
                                            <p class="text-muted">We put in a lot of hard work and effort to develop the MVP in a challenging one-day and night marathon session.</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="item event-list active">
                                    <div>
                                        <div class="event-date">
                                            <div class="text-primary mb-1">February, 2023</div>
                                            <h5 class="mb-4">Signing First MOU</h5>
                                        </div>
                                        <div class="event-down-icon">
                                            <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                        </div>

                                        <div class="mt-3 px-3">
                                            <p class="text-muted">We are finalizing our first Memorandum of Understanding with a startup entity, set to be signed within the next two months.</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="item event-list">
                                    <div>
                                        <div class="event-date">
                                            <div class="text-primary mb-1">March, 2023</div>
                                            <h5 class="mb-4">MVP 2</h5>
                                        </div>
                                        <div class="event-down-icon">
                                            <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                        </div>

                                        <div class="mt-3 px-3">
                                            <p class="text-muted">We will develop MVP2 based on initial user feedback. It's an improved version based on the data we gathered.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="item event-list">
                                    <div>
                                        <div class="event-date">
                                            <div class="text-primary mb-1">April, 2023</div>
                                            <h5 class="mb-4">Beta Release</h5>
                                        </div>
                                        <div class="event-down-icon">
                                            <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                        </div>

                                        <div class="mt-3 px-3">
                                            <p class="text-muted">We are releasing a beta version for testing and feedback before official launch. It gives us an opportunity to provide services all users.</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="item event-list">
                                    <div>
                                        <div class="event-date">
                                            <div class="text-primary mb-1">May, 2023</div>
                                            <h5 class="mb-4">Final Product Rollout</h5>
                                        </div>
                                        <div class="event-down-icon">
                                            <i class="bx bx-down-arrow-circle h1 text-primary down-arrow-icon"></i>
                                        </div>

                                        <div class="mt-3 px-3">
                                            <p class="text-muted">The final product launch is the official release after testing and feedback, now available to the public for use. It marks the end of the development process.</p>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </section>
    <!-- Roadmap end -->

    <section class="section" id="pricing">
        <div class="bg-overlay bg-primary"></div>

        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mb-5">
                        <h1 class="text-white fw-semibold mb-3 hero-title">Pricing</h1>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                    <div class="card overflow-hidden">
                        <div class="card-header text-center">
                            <h3 class="mb-0">Cost/Credits</h3>
                        </div>
                        <div class="card-header text-center">
                            <h5 class="mb-0">Rs. 100 / 10000 Credits</h5>
                            <p>1 redit = 1 request</p>
                        </div>
                        <div class="card-header text-center">
                            <p>*Minimum Quntity 1,00,000 Credits</p>
                        </div>
                        <div class="card-header text-center">
                            <p>contact for more details at kiran@einzag.in</p>
                        </div>

                    </div>
                </div>
                <div class="col-md-3"></div>

            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </section>
    <!-- Faqs end -->

    <!-- Footer start -->
    <footer class="landing-footer">
        <div class="container">

            <div class="row">
                <div class="col-lg-6">
                    <div class="mb-4">
                        <img src="assets/images/logo_side.png" alt="" height="40">
                    </div>

                    <p class="mb-2">{{year}} © Einzag. Design & Develop by Einzag</p>
                    <p class="mb-2">Email :<b> kiran@einzag.in</b></p>
                    <p class="mb-2">Mobile :<b> 9346757431</b></p>
                    <br/>
                    <p class="mb-2">Address :<b><br/>4-31/657, Madhapur, <br/> Hyderabad, Telangana 500073</b></p>
                    <p class="mb-2"><a aria-label="linkedin_url"  class="mb-2" href="https://www.linkedin.com/company/einzag" target="_blank"><i class="text-success fab fa-linkedin fa-2x"></i></a></p>
                    <!-- <p>It will be as simple as occidental in fact, it will be to an english person, it will seem like
                        simplified English, as a skeptical</p> -->
                </div>

                <!-- <div class="col-lg-3 col-sm-6">
                    <div class="mb-4 mb-lg-0">
                        <h5 class="mb-3 footer-list-title">Company</h5>
                        <ul class="list-unstyled footer-list-menu">
                            <li><a  aria-label="linkedin"  href="javascript: void(0);">About Us</a></li>
                            <li><a aria-label="features" href="javascript: void(0);">Features</a></li>
                            <li><a aria-label="faq" href="javascript: void(0);">FAQs</a></li>
                        </ul>
                    </div>
                </div> -->
                <div class="col-lg-3 col-sm-6">
                    <div class="mb-4 mb-lg-0">
                        <h5 class="mb-3 footer-list-title">Resources</h5>
                        <ul class="list-unstyled footer-list-menu">
                            <!-- <li><a href="javascript: void(0);">Whitepaper</a></li>
                            <li><a href="javascript: void(0);">Token sales</a></li> -->
                            <li><a aria-label="provacy_policy" href="/privacy-policy">Privacy Policy</a></li>
                            <li><a aria-label="terms" href="/terms-conditions">Terms & Conditions</a></li>
                            <li><a aria-label="terms" href="/refund-policy">Refund policy</a></li>

                        </ul>
                    </div>
                </div>
                
                <!-- <div class="col-lg-3 col-sm-6">
                    <div class="mb-4 mb-lg-0">
                        <h5 class="mb-3 footer-list-title">Links</h5>
                        <ul class="list-unstyled footer-list-menu">
                            <li><a href="javascript: void(0);">Tokens</a></li>
                            <li><a href="javascript: void(0);">Roadmap</a></li>
                            <li><a href="javascript: void(0);">FAQs</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="mb-4 mb-lg-0">
                        <h5 class="mb-3 footer-list-title">Latest News</h5>
                        <div class="blog-post">
                            <a href="javascript: void(0);" class="post">
                                <div class="badge badge-soft-success font-size-11 mb-3">Cryptocurrency</div>
                                <h5 class="post-title">Donec pede justo aliquet nec</h5>
                                <p class="mb-0"><i class="bx bx-calendar me-1"></i> 04 Mar, 2020</p>
                            </a>
                            <a href="javascript: void(0);" class="post">
                                <div class="badge badge-soft-success font-size-11 mb-3">Cryptocurrency</div>
                                <h5 class="post-title">In turpis, Pellentesque</h5>
                                <p class="mb-0"><i class="bx bx-calendar me-1"></i> 12 Mar, 2020</p>
                            </a>

                        </div>
                    </div>
                </div> -->
            </div>
            <!-- end row -->

            <!-- <hr class="footer-border my-5">

            <div class="row">
                <div class="col-lg-6">
                    <div class="mb-4">
                        <img src="assets/images/logo_side.png" alt="" height="40">
                    </div>

                    <p class="mb-2">{{year}} © Einzag. Design & Develop by Einzag</p>
                </div>

            </div> -->
        </div>
        <!-- end container -->
    </footer>
    <!-- Footer end -->

</div>