<nav class="navbar navbar-expand-lg navigation fixed-top sticky" (window:scroll)="windowScroll()" id="navbar">
    <div class="container">
        <a class="navbar-logo" routerLink="/" aria-label="logo">
            <img src="assets/images/logo_side_dark.png" alt="" height="35" class="logo logo-dark">
            <img src="assets/images/logo_side.png" alt="" height="35" class="logo logo-light">
        </a>

        <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" aria-label="menu_toggle"
            data-toggle="collapse" data-target="#topnav-menu-content" (click)="toggleMenu()">
            <i class="fa fa-fw fa-bars"></i>
        </button>

        <div class="collapse navbar-collapse" id="topnav-menu-content">
            <ul class="navbar-nav ms-auto" id="topnav-menu">
                <li class="nav-item">
                    <a aria-label="nav_home" class="nav-link" href="/" [ngxScrollTo]="'home'"
                        [ngClass]="{'active':currentSection === 'home'}">Home</a>
                </li>

            </ul>

            <!-- <div class="my-2 ms-lg-2">
                <a href="javascript: void(0);" class="btn btn-outline-success w-xs">Sign in</a>
            </div> -->
        </div>
    </div>
</nav>
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
    <section class="section hero-section bg-ico-hero" id="home">
        <div class="bg-overlay bg-primary"></div>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="card overflow-hidden">
                        <div class="card-header text-center">
                            <h5 class="mb-0">Refund Policy</h5>
                        </div>
                        <div class="card-body">
                            <h1>Introduction</h1>
                            <p>Last updated: February 03, 2023</p>
                            <p>Effective: February 03, 2023</p>

                            <p>At our company, we offer the ability for customers to request a refund for unused prepaid
                                infra credits in their customer account. If you have any unused credits and do not
                                intend to use any further services, you can request a refund by following the guidelines
                                outlined below.</p>

                            <h1>Eligibility for Refund</h1>
                            <p>Customers are eligible for a refund if they have unused credits in their
                                customer account."</p>
                            <p>Customers can request a refund by sending an email to kiran@einzag.in.
                            </p>
                            <p>* Amount will be refunded within 5 to 7 working days.</p>
                            <h1>Fee & Charges</h1>
                            <p>Charges will be deducted if any GST or Services are applicable.</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </section>
    <footer class="landing-footer">
        <div class="container">

            <div class="row">
                <div class="col-lg-6">
                    <div class="mb-4">
                        <img src="assets/images/logo_side.png" alt="" height="40">
                    </div>

                    <p class="mb-2">{{year}} © Einzag. Design & Develop by Einzag</p>
                    <p class="mb-2">Email :<b> kiran@einzag.in</b></p>
                    <p class="mb-2">Mobile :<b> 9346757431</b></p>
                    <br/>
                    <p class="mb-2">Address :<b><br/>8-3-825/5/5/6/1, Yella Reddy Guda, <br/> Hyderabad, Telangana 500073</b></p>
                    <p class="mb-2"><a aria-label="linkedin_url"  class="mb-2" href="https://www.linkedin.com/company/einzag" target="_blank"><i class="text-success fab fa-linkedin fa-2x"></i></a></p>
                    <!-- <p>It will be as simple as occidental in fact, it will be to an english person, it will seem like
                        simplified English, as a skeptical</p> -->
                </div>

                <!-- <div class="col-lg-3 col-sm-6">
                    <div class="mb-4 mb-lg-0">
                        <h5 class="mb-3 footer-list-title">Company</h5>
                        <ul class="list-unstyled footer-list-menu">
                            <li><a  aria-label="linkedin"  href="javascript: void(0);">About Us</a></li>
                            <li><a aria-label="features" href="javascript: void(0);">Features</a></li>
                            <li><a aria-label="faq" href="javascript: void(0);">FAQs</a></li>
                        </ul>
                    </div>
                </div> -->
                <div class="col-lg-3 col-sm-6">
                    <div class="mb-4 mb-lg-0">
                        <h5 class="mb-3 footer-list-title">Resources</h5>
                        <ul class="list-unstyled footer-list-menu">
                            <!-- <li><a href="javascript: void(0);">Whitepaper</a></li>
                            <li><a href="javascript: void(0);">Token sales</a></li> -->
                            <li><a aria-label="provacy_policy" href="/privacy-policy">Privacy Policy</a></li>
                            <li><a aria-label="terms" href="/terms-conditions">Terms & Conditions</a></li>
                            <li><a aria-label="terms" href="/refund-policy">Refund policy</a></li>

                        </ul>
                    </div>
                </div>
                
                <!-- <div class="col-lg-3 col-sm-6">
                    <div class="mb-4 mb-lg-0">
                        <h5 class="mb-3 footer-list-title">Links</h5>
                        <ul class="list-unstyled footer-list-menu">
                            <li><a href="javascript: void(0);">Tokens</a></li>
                            <li><a href="javascript: void(0);">Roadmap</a></li>
                            <li><a href="javascript: void(0);">FAQs</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="mb-4 mb-lg-0">
                        <h5 class="mb-3 footer-list-title">Latest News</h5>
                        <div class="blog-post">
                            <a href="javascript: void(0);" class="post">
                                <div class="badge badge-soft-success font-size-11 mb-3">Cryptocurrency</div>
                                <h5 class="post-title">Donec pede justo aliquet nec</h5>
                                <p class="mb-0"><i class="bx bx-calendar me-1"></i> 04 Mar, 2020</p>
                            </a>
                            <a href="javascript: void(0);" class="post">
                                <div class="badge badge-soft-success font-size-11 mb-3">Cryptocurrency</div>
                                <h5 class="post-title">In turpis, Pellentesque</h5>
                                <p class="mb-0"><i class="bx bx-calendar me-1"></i> 12 Mar, 2020</p>
                            </a>

                        </div>
                    </div>
                </div> -->
            </div>
            <!-- end row -->

            <!-- <hr class="footer-border my-5">

            <div class="row">
                <div class="col-lg-6">
                    <div class="mb-4">
                        <img src="assets/images/logo_side.png" alt="" height="40">
                    </div>

                    <p class="mb-2">{{year}} © Einzag. Design & Develop by Einzag</p>
                </div>

            </div> -->
        </div>
        <!-- end container -->
    </footer>

</div>