import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { Page404Component } from './extrapages/page404/page404.component';
import { LandingComponent } from './landing/landing.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { RefundComponent } from './refund/refund.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  // { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'home', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
  // { path: 'pages', loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule), canActivate: [AuthGuard] },
  { path: 'privacy-policy',component:PrivacyComponent},
  { path: 'terms-conditions',component:TermsComponent},
  { path: 'refund-policy',component:RefundComponent},

  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
